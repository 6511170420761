import { useCallback, useEffect, useState } from 'react';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebars/Sidebar';
import './update.css'
import { DriveFolderUploadOutlined } from '@mui/icons-material';
import { onValue, ref, update } from 'firebase/database';
import { db, storage } from '../../firebase';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { ref as storeRef } from 'firebase/storage';
import ActivityLog from '../activity/ActivityLog';

const UpdateAdmin = () => {
    const [image, setImage] = useState(null);
    const [url, setUrl] = useState("");
    const [state, setState] = useState({});
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const { id } = useParams();

    /**
       * Set chosen image to a variable
       * @param {*} e 
       */
    const handleImgChoose = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }

    }
    const goBackHandler = () => {
        window.history.back();
    };

    const fetchAdminData = useCallback(() => {
        const dbRef = ref(db, `Admins/${id}`);
        const unsubscribe = onValue(dbRef, (snapshot) => {
            if (snapshot.exists()) {
                setState({ ...snapshot.val() });
            }
        });

        return () => unsubscribe(); // Clean up listener
    }, [id]);

    useEffect(() => {
        fetchAdminData();
    }, [fetchAdminData]);

    /**
      * Update the value of input field on change
      * @param {*} e 
      */
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };
    const uploadImage = async () => {
        const storageRef = storeRef(storage, '/admins/' + id);
        const uploadTask = uploadBytesResumable(storageRef, image);

        return new Promise((resolve, reject) => {
            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgress(progress);
                },
                (error) => reject(error),
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    resolve(downloadURL);
                }
            );
        });
    };

    const updateAdminData = async (imageUrl) => {
        const adminData = {
            email: state.email,
            name: state.name || "",
            address: state.address || "",
            phone: state.phone || "",
            role: state.role || "",
            dob: state.dob || "",
            gender: state.gender || "",
            notes:state.notes||"",
            imageUrl: imageUrl || state.imageUrl || "",
            updated_at: new Date().toLocaleString(),
            updated_by: sessionStorage.getItem("admin_name")
        };

        await update(ref(db, `Admins/${id}`), adminData);
        ActivityLog('Admin updated', `${state.email} updated`);
        toast.success("Admin updated successfully.");
        navigate('/admins/view/' + id);
    };


    /**
     * this method updates existing user to the system
     * @param {*} e 
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            let imageUrl;
            if (image) {
                imageUrl = await uploadImage();
            }

            await updateAdminData(imageUrl);
        } catch (error) {
            toast.error(`Error: ${error.message}`);
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='ua-new'>
            <Sidebar />
            <div className="ua-newContainer">
                {/* <Navbar /> */}
                <div className="ua-top">
                    <h1>Update Admin</h1>
                </div>
                <div className="ua-bottom">
                    <div className="ua-left">
                    <img className='ua-na-img' src={
                            image ? URL.createObjectURL(image)
                                : (state.imageUrl ?
                                    state.imageUrl
                                    : 'https://img.favpng.com/8/0/5/computer-icons-user-profile-avatar-png-favpng-6jJk1WU2YkTBLjFs4ZwueE8Ub.jpg'
                                )} alt=''
                        />
                        <div className="formInput ua-img-input">
                            {progress ?
                                <progress value={progress} max="100" />
                                : <br />
                            }<br />
                            <label htmlFor="file">
                                Image:<DriveFolderUploadOutlined className='icon' />
                            </label>
                            <input
                                type='file'
                                id='file'
                                onChange={handleImgChoose}

                                style={{ display: 'none' }}
                            />
                        </div>
                    </div>
                    <div className="ua-right">
                        <form onSubmit={handleSubmit}>
                            <div className="formInput" >
                                <label>Email</label>
                                <input type='email'
                                    required
                                    name='email'
                                    disabled
                                    value={state.email}
                                    placeholder='Email'
                                    onChange={handleInputChange} />
                            </div>
                            <div className="formInput" >
                                <label>Name</label>
                                <input type='text'
                                    placeholder='Name '
                                    name='name'
                                    value={state.name}
                                    onChange={handleInputChange} />
                            </div>
                            <div className="formInput" >
                                <label> Address</label>
                                <input type='text'
                                    placeholder=' Address'
                                    name='address'
                                    value={state.address}
                                    onChange={handleInputChange} />
                            </div>
                            <div className="formInput" >
                                <label>Phone</label>
                                <input type='text'
                                    placeholder='Phone'
                                    name='phone'
                                    value={state.phone}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="formInput" >
                                <label>Role</label>
                                <input type='text'
                                    placeholder='Role'
                                    name='role'
                                    value={state.role}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="formInput" >
                                <label>Date of Birth</label>
                                <input type='date'
                                    placeholder='Date of Birth'
                                    name='dob'
                                    value={state.dob}
                                    onChange={handleInputChange} />
                            </div>

                            <div className="formInput" >
                                <label>Gender</label>
                                <input type='text'
                                    name='gender'
                                    value={state.gender}
                                    placeholder='Gender'
                                    onChange={handleInputChange} />
                            </div>
                            <div className="formInput" >
                                <label>Notes</label>
                                <input type='text'
                                    placeholder='Notes'
                                    name='notes'
                                    value={state.notes}
                                    onChange={handleInputChange} />
                            </div>
                            <button type='button' className='ua-btn-back' onClick={goBackHandler}>Go Back</button>
                            <button className='ua-updateBtn'>Update</button>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateAdmin