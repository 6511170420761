
import Sidebar from "../../components/sidebars/Sidebar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { onValue, ref } from "firebase/database";
import { auth, db } from "../../firebase";
const ViewCalibration = () => {
    const [info, setInfo] = useState({});
    const { id } = useParams();
    const { rego } = useParams();

    const goBackHandler = () => {
        window.history.back();
    };
    useEffect(() => {
        const dbRef = ref(db, `Vehicles/${rego}/calibrations/${id}`);
        onValue(dbRef, (snapshot) => {
            // dbRef.get().then((snapshot)=>{
            if (snapshot.exists()) {
                setInfo({ ...snapshot.val() });
            } else {
                setInfo({})
            }
        });

    }, [id, rego]);

    console.log('rego filter', info);

    return (
        <div className="single">
               {auth.currentUser ? (<>
                <Sidebar />
            </>) : null}
            <div className="singleContainer">
                {/* <Navbar /> */}
                <div className="top">
                    <div className="item">
                        <div className="details">
                            <h1 className="itemTitle">{info.name}</h1>
                            <div className="detailItem">
                                <span className="itemKey">Axle Type:</span>
                                <span className="itemValue">{info.axle_type}</span>
                            </div>
                            <div className="detailItem">
                                <span className="itemKey">Calibration Attribute:</span>
                                <span className="itemValue">{info.calibration_attribute}</span>
                            </div>
                            <div className="detailItem">
                                <span className="itemKey">Calibration Value:</span>
                                <span className="itemValue">
                                    {info.calibration_value}
                                </span>
                            </div>

                            <div className="detailItem">
                                <span className="itemKey">Calibrating Technician:</span>
                                <span className="itemValue">
                                    {info.calibrating_technician}
                                </span>
                            </div>
                            <div className="detailItem">
                                <span className="itemKey">Calibration Date Time:</span>
                                <span className="itemValue">{info.calibration_date_time}</span>
                            </div>
                            <div className="detailItem">
                                <span className="itemKey">Calibration Due Date:</span>
                                <span className="itemValue">{info.calibration_due_date}</span>
                            </div>

                            <button className='btn-back' onClick={goBackHandler}>Go Back</button>

                        </div>
                    </div>
                </div>
                <div className="bottom">

                </div>
            </div>
        </div>
    )
}

export default ViewCalibration