import { push, ref } from 'firebase/database';
import React from 'react'
import { db } from '../../firebase';

const ActivityLog = (action, details) => {

    const logRef = ref(db, 'ActivityLogs');
    const logData = {
        action,
        details,
        name: sessionStorage.getItem("admin_name"),
        email: sessionStorage.getItem("admin_email"),
        timestamp: new Date().toLocaleString(),

    };
    push(logRef, logData)
        .then(() => {
            console.log('Activity log saved successfully.');
        })
        .catch((error) => {
            console.error('Error saving activity log:', error);
        });

}

export default ActivityLog