import React, { useEffect, useState } from "react";
import { FaCheck, FaEye, FaSistrix, FaTimes } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { onValue, ref } from "firebase/database";
import { db } from "../firebase";
import './homepage.css';

const HomePage = (props) => {
    const [data, setData] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(data);

    // Fetch data from Firebase Realtime Database
    const fetchData = async () => {
        const dbRef = ref(db, `Vehicles/`);
        onValue(dbRef, (snapshot) => {
            if (snapshot.val() !== null) {
                setData(snapshot.val());
            }
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredData(data);
        }
        else {
            // Filter the object based on a condition
            const filtered = Object.fromEntries(
                Object.entries(data).filter(([key, value]) => {
                    const keyMatch = key.includes(searchQuery.toLowerCase());
                    const regoMatch = value.rego && value.rego.toLowerCase().includes(searchQuery.toLowerCase());
                    const vinMatch = value.rego && value.vin.toLowerCase().includes(searchQuery.toLowerCase());
                    return keyMatch || regoMatch || vinMatch;
                })
            );

            setFilteredData(filtered);
            console.log('last:', filtered);
        }

    }, [searchQuery, data])

    return (
        <div className='home__main'>
            <div className="home__container">
                <div className="home__logo mt-4">
                    <img src="/images/TSCR.png" className="image__logo mt-4" alt="" />

                </div>
                <SearchBar setSearchQuery={setSearchQuery} />
                {searchQuery ? <DataTable data={filteredData}
                /> : <DefaultContent />}
            </div>
        </div>


    )
}
const SearchBar = ({ setSearchQuery }) => (
    <form className='home__form'>
        <input
            type="text"
            className='home__input'
            onChange={(e) => setSearchQuery(e.target.value)}
            required
            placeholder="Search by Rego/VIN..."
        />
        {/* <FaSistrix className='search__icon' /> */}
    </form>
);
const DataTable = ({ data }) => (
    <div className="home__group">
        <table className='styled-table'>
            <thead>
                <tr>
                    <th style={{ textAlign: "center" }}>Rego</th>
                    <th style={{ textAlign: "center" }}>VIN</th>
                    <th style={{ textAlign: "center" }}>Warranty Expiry</th>
                    <th style={{ textAlign: "center" }}>Scale Serial Number</th>
                    <th style={{ textAlign: "center" }}>Valid Calibration</th>
                    <th style={{ textAlign: "center" }}>Certificate</th>
                    <th style={{ textAlign: "center" }}>Action</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(data).map((id) => (
                    <DataRow key={id} data={data[id]} id={id} />
                ))}
            </tbody>
        </table>
    </div>
);
const DataRow = ({ data, id, isSmallDevice, isSmallerDevice }) => (
    <tr>
        <th scope='row'>
            <div className="vehicleListItem">
                {data.rego}
            </div>
        </th>
        <td>{data.vin}</td>
        <td>{data.warranty_expiry_date}</td>
        <td>{data.scale_serial_number}</td>
        <td> {data.calibration_validated ? (
            <FaCheck className='checkmark checkmark--yes' />
        ) : (
            <FaTimes className='checkmark checkmark--no' />
        )}
        </td>
        <td>
            {data.certificate ? (
                <a href={data.certificate} target="_blank" rel="noopener noreferrer"
                >View</a>
            ) : (
                'N/A'
            )
            }
        </td>

        <td style={{ display: 'flex' }}>
            <Link to={`/vehicles/view/${id}`}>
                <FaEye className="view-icon" tooltip='view' />
            </Link>
        </td>
    </tr>
);
const DefaultContent = () => (
    <>
        <div className="btn__container">
            <p><a className='home__btn' href="/login">Admin Login</a></p>
            <p><a className='tech__btn' href="/tech-login">Tech Login</a></p>
            <p><a className='register__btn' href="/tech-register">Tech Register</a></p>
        </div>
        <div className="home__content">
            <p>
                The Truck Scale Calibration Registry offers the public a free view of calibration data for on-board truck
                scales installed in heavy vehicles across Australia. It grants access to publicly available information submitted by technicians during the installation and calibration of on-board truck scales.
            </p>
            <p>
                This registry has been meticulously designed to aid governments and corporations in fulfilling their Chain of Responsibility duty. Its purpose is to combat the dangers associated with fraudulent scale calibrations and the profoundly hazardous risks posed by overloaded heavy vehicles on our roads.
            </p>
            <p>   The Truck Scale Calibration Registry serves as a valuable resource for industry professionals, ensuring the integrity and accuracy of weight measurements. By promoting transparency and accountability in the calibration process, it contributes to safer roads, more efficient logistics, and fair trade practices. As technology advances and regulations evolve, this registry remains committed to staying at the forefront,
                continuously enhancing its capabilities to support the transportation industry and uphold the highest standards of safety and compliance.  </p>
        </div>
    </>
);
export default HomePage