import Sidebar from '../../components/sidebars/Sidebar'
import Widget from '../../components/widget/Widget'
import './home.scss'

const Home = () => {
    return (
        <div className='home'>
            <Sidebar/>
            <div className="homeContainer">
                <div className="widgets">
                <Widget type='admin'/>
                <Widget type='vehicle'/>
                <Widget type='application'/>
                <Widget type='technician'/>

                </div>
            </div>
        </div>
    )
}

export default Home;