import { useEffect, useState } from 'react';
import Sidebar from '../../components/sidebars/Sidebar';
import { onValue, ref, update } from 'firebase/database';
import { db, storage } from '../../firebase';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from 'firebase/storage'
import { InfinitySpin } from 'react-loader-spinner';

const UpdateVehicle = () => {
  const [state, setState] = useState({});
  const navigate = useNavigate();
  // const [info, setInfo] = useState({});
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e) => {
    let selectedImages = [...e.target.files];
    let newImages = images.concat(selectedImages);
    setImages(newImages);
  };

  const { id } = useParams();

  useEffect(() => {
    const dbRef = ref(db, `Vehicles/${id}`);
    onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        setState({ ...snapshot.val() });
      }
    });
  }, [id]);

  /**
     * Update the value of input field on change
     * @param {*} e 
     */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
    // console.log(state)
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
  const getVehicleData = () => ({
    rego: state.rego ? state.rego : id,
    vin: state.vin ? state.vin : "",
    scale_serial_number: state.scale_serial_number ? state.scale_serial_number : "",
    fitted_location: state.fitted_location ? state.fitted_location : "",
    fitted_date: state.fitted_date ? state.fitted_date : "",
    warranty_expiry_date: state.warranty_expiry_date ? state.warranty_expiry_date : "",
    company_name: state.company_name ? state.company_name : "",
    driver_name: state.driver_name ? state.driver_name : "",
    notes: state.notes ? state.notes : "",
    next_calibration_due: state.next_calibration_due ? state.next_calibration_due : "",
    make: state.make || "",
    model: state.model || "",
    calibration_validated: state.calibration_validated || "",
    certificate: state.certificate || "",
    updated_at: new Date().toLocaleString(),
    updated_by: sessionStorage.getItem("admin_name"),

  })

  const updateVehicleInDb = async (vehicleData) => {
    try {
      setLoading(true);
      await update(ref(db, `Vehicles/${id}`), vehicleData);
      toast.success("Vehicle updated successfully.");
      console.log("Vehicle updated.");
      setTimeout(() => navigate(`/vehicles/view/${id}`), 1000);

    } catch (error) {
      toast.error('Error updating vehicle:', error);
      console.error('Error updating vehicle:', error);
    }
    finally {
      setLoading(false);
    }
  }

  /**
       * this method updates existing vehicle to the system
       * @param {*} e 
  */
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('state', images);
    const vehicleData = getVehicleData();
    if (state.certificate) {
      const certificateRef = storageRef(storage, `certificates/${state.rego}/${state.certificate.name}`);
      await uploadBytesResumable(certificateRef, state.certificate);
      const certificateUrl = await getDownloadURL(certificateRef);
      vehicleData.certificate = certificateUrl; // Save the certificate URL to vehicleData
    }
    if (images.length > 0) {
      console.log(images)
      const imageUrls = [];
      setLoading(true); // Set loading to true when you start uploading images

      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const imageRef = storageRef(storage, `vehicles/${state.rego}/` + image.name);
        //upload image
        await uploadBytesResumable(imageRef, image);
        //get download url
        const imageUrl = await getDownloadURL(imageRef);
        imageUrls.push(imageUrl);
      }
      vehicleData.imageUrls = imageUrls;
      console.log('vd', vehicleData.imageUrls)
      await updateVehicleInDb(vehicleData);
    } else {
      console.log('si', state.imageUrls)
      await updateVehicleInDb(vehicleData);
    }

  };
  const goBackHandler = () => {
    window.history.back();
  };

  return (
    <div className='new'>
      <Sidebar />
      <div className="newContainer">
        {/* <Navbar /> */}
        <div className="top">
          <h1>Update Vehicle</h1>
        </div>
        <div className="bottom">

          <div className="right">
            {loading ? (
              <div className='row flex justify-content-center'>
                <InfinitySpin
                  type="TailSpin"
                  color="#00BFFF"
                  height={100}
                  width={100}
                />
                <h4 className='d row flex justify-content-center'>Uploading data...</h4>
              </div>) : (
              <>
                <form onSubmit={handleSubmit}>

                  <div className="formInput" >
                    <label>Rego</label>
                    <input type='text'
                      placeholder='Rego'
                      required
                      disabled
                      name='rego'
                      value={state.rego ? state.rego : id}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="formInput" >
                    <label>VIN</label>
                    <input type='text'
                      placeholder='Vehicle Identification Number '
                      name='vin'
                      value={state.vin}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="formInput" >
                    <label>Scale Serial Number</label>
                    <input type='text'
                      placeholder='Scale Serial Number'
                      name='scale_serial_number'
                      value={state.scale_serial_number}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="formInput" >
                    <label>Fitted location</label>
                    <input type='text'
                      placeholder='Fitted location'
                      name='fitted_location'
                      value={state.fitted_location}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="formInput" >
                    <label>Fitted Date</label>
                    <input type='date'
                      placeholder='Fitted Date'
                      name='fitted_date'
                      value={state.fitted_date}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="formInput" >
                    <label>Next Calibration Due</label>
                    <input type='date'
                      placeholder='Next Calibration Due'
                      name='next_calibration_due'
                      value={state.next_calibration_due}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="formInput" >
                    <label>Make</label>
                    <input type='text'
                      placeholder='Make '
                      name='make'
                      value={state.make}
                      onChange={handleInputChange}
                    />
                  </div> <div className="formInput" >
                    <label>Model</label>
                    <input type='text'
                      placeholder='Model'
                      name='model'
                      value={state.model}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="formInput" >
                    <label>Warranty Expiry Date</label>
                    <input type='date'
                      placeholder='Warranty Expiry Date'
                      name='warranty_expiry_date'
                      value={state.warranty_expiry_date}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="formInput" >
                    <label>Company Name</label>
                    <input type='text'
                      placeholder='Company Name'
                      name='company_name'
                      value={state.company_name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="formInput" >
                    <label>Driver Name</label>
                    <input type='text'
                      placeholder='Driver Name'
                      name='driver_name'
                      value={state.driver_name}
                      onChange={handleInputChange}

                    />
                  </div>
                  <div className="formInput calibration_validated">
                    <label>Is Calibration Validated </label>
                    <input
                      type='checkbox'
                      name='calibration_validated'
                      checked={state.calibration_validated}
                      onChange={handleCheckboxChange}
                    />

                  </div>
                  <div className="formInput upload-certificate" >
                    <label>Upload Certificate</label>
                    <input
                      name="certificate"
                      type="file"
                      onChange={(e) => setState((prevState) => ({
                        ...prevState,
                        certificate: e.target.files[0],
                      }))} />
                    {/* {state.certificate && (
                      <div>
                        <span className='itemValue'>
                          <a href={state.certificate} target="_blank" rel="noopener noreferrer"
                          >Uploaded Certificate</a>
                        </span>
                      </div>
                    )} */}
                  </div>
                  <div className="formInput" >
                    <label>Notes</label>
                    <input type='text'
                      placeholder='Notes'
                      name='notes'
                      value={state.notes}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div
                    className='imageContainer'
                  >
                    <label htmlFor="file-input">Select Images</label>
                    <input id="file-input" type="file" multiple onChange={handleImageChange} />

                    <div className="image-previews">
                      {images.map((img, idx) => (
                        <div key={idx} className="image-container">
                          <img src={URL.createObjectURL(img)} alt="Preview"

                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <br />
                  <button className='btn-save'>Update</button>
                </form>
                <div className="btnContainer">

                  <button className="btn-back" onClick={goBackHandler}>
                    Go Back
                  </button>
                </div>
              </>
            )}


          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateVehicle