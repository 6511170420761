import {
    AccountCircleOutlined, AddCardOutlined, AddIcCall, AddModeratorOutlined,
    AddToQueueOutlined,
    ExitToApp, FireTruckOutlined,
    PersonalVideo,
    PsychologyOutlined, SettingsApplications
} from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';

import './sidebar.scss';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { auth } from '../../firebase';
import icon from '../../TSCR-01 logo.png';

const navigationItems = [
    { title: "MAIN", links: [{ to: '/dashboard', icon: DashboardIcon, label: 'Dashboard' }] },
    { title: "LISTS", links: 
    [{ to: '/vehicles', icon: FireTruckOutlined, label: 'Vehicles' }, 
    { to: '/admins', icon: AddModeratorOutlined, label: 'Admins' },
    { to: '/applications', icon: AddCardOutlined, label: 'Applications' },
    { to: '/technicians', icon: AddIcCall, label: 'Technicians' }] },
    { title: "SERVICE", links: [{ icon: PsychologyOutlined, label: 'Logs' }, { icon: SettingsApplications, label: 'Settings' }] },
    { title: "USER", links: [{ to: '/my-profile', icon: AccountCircleOutlined, label: 'My Profile' }] }
];

const Sidebar = () => {
    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
            await auth.signOut();
            sessionStorage.removeItem('admin_name');
            // For security, consider removing the Auth Token entirely rather than setting it to an empty string
            sessionStorage.removeItem("Auth Token");
            toast.info('Logged out successfully!');
            navigate("/login");
        } catch (error) {
            toast.error('Logout error:', error);
            console.error('Logout error:', error);
        }
    };

    return (
        <div className='sidebar'>
            <div className='top'>
                <Link to="/dashboard" >
                    <div className=" mt-3 logo-container">
                        <img src={icon} alt='TSCR logo' className='icon' />
                    </div>
                </Link>
            </div>
            <hr />
            <div className='center'>
                <ul>
                    {navigationItems.map((section) => (
                        <>
                            <p className="title">{section.title}</p>
                            {section.links.map(link => (
                                link.to ? (
                                    <Link to={link.to} className="sidebar-link">
                                        <li>
                                            <link.icon className='icon' />
                                            <span>{link.label}</span>
                                        </li>
                                    </Link>
                                ) : (
                                    <li>
                                        <link.icon className='icon' />
                                        <span>{link.label}</span>
                                    </li>
                                )
                            ))}
                        </>
                    ))}
                    <li onClick={handleLogout} className="logout-item">
                        <ExitToApp className='icon' />
                        <span>Logout</span>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Sidebar;
