
import Sidebar from "../../components/sidebars/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { onValue, ref } from "firebase/database";
import { auth, db, storage } from "../../firebase";

const Admin = () => {
    const [info, setInfo] = useState({});
    const { id } = useParams();

    const goBackHandler = () => {
        window.history.back();
    };

    useEffect(() => {
        const dbRef = ref(db, `Admins/${id}`);
        onValue(dbRef, (snapshot) => {
            if (snapshot.exists()) {
                setInfo({ ...snapshot.val() });
            } else {
                setInfo({})
            }
        });
    }, [id]);

    console.log("single user data", info)
    return (
        <div className="single">
            <Sidebar />
            <div className="singleContainer">
                {/* <Navbar /> */}
                <div className="top">
                    {/* <div className="left"> */}
                    <Link to={`/admins/update/${id}`}>
                        <div className="editButton">Edit</div>
                    </Link>
                    {/* <h1 className="title">Information</h1> */}
                    <div className="item">
                        <img
                            src={info.imageUrl ? info.imageUrl :
                                'https://img.favpng.com/8/0/5/computer-icons-user-profile-avatar-png-favpng-6jJk1WU2YkTBLjFs4ZwueE8Ub.jpg'
                            }
                            alt=""
                            className="itemImg"
                        />
                        <div className="details">
                            <h1 className="itemTitle">{info.name}</h1>
                            <div className="detailItem">
                                <span className="itemKey">Email:</span>
                                <span className="itemValue">{info.email}</span>
                            </div>
                            <div className="detailItem">
                                <span className="itemKey">Phone:</span>
                                <span className="itemValue">{info.phone}</span>
                            </div>
                            <div className="detailItem">
                                <span className="itemKey">Role:</span>
                                <span className="itemValue">
                                    {info.role}
                                </span>
                            </div>
                            <div className="detailItem">
                                <span className="itemKey">DOB:</span>
                                <span className="itemValue">{info.dob}</span>
                            </div>
                            <div className="detailItem">
                                <span className="itemKey">Address:</span>
                                <span className="itemValue">
                                    {info.address}
                                </span>
                            </div>
                            <div className="detailItem">
                                <span className="itemKey">Gender :</span>
                                <span className="itemValue">{info.gender}</span>
                            </div>
                            <div className="detailItem">
                                <span className="itemKey">Notes :</span>
                                <span className="itemValue">{info.notes}</span>
                            </div>

                            {info.added_at ?
                                <div className="detailItem">
                                    <span className="itemKey">Added At:</span>
                                    <span className="itemValue">
                                        {info.added_at}
                                    </span>
                                </div> : null
                            }
                            {info.added_by ?
                                <div className="detailItem">
                                    <span className="itemKey">Added By:</span>
                                    <span className="itemValue">
                                        {info.added_by}
                                    </span>
                                </div>
                                : null
                            }
                            {info.updated_at ?
                                <div className="detailItem">
                                    <span className="itemKey">Last Updated At:</span>
                                    <span className="itemValue">
                                        {info.updated_at}
                                    </span>
                                </div>
                                : null
                            }
                            {info.updated_by ?
                                <div className="detailItem">
                                    <span className="itemKey">Last Updated By:</span>
                                    <span className="itemValue">
                                        {info.updated_by}
                                    </span>
                                </div>
                                : null
                            }
                            <button className='btn-back' onClick={goBackHandler}>Go Back</button>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <h1 className="title">Last Transactions</h1>
                    {/* <List /> */}
                </div>
            </div>
        </div>
    );
};

export default Admin;