import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { auth } from './firebase';

const PrivateRoute = () => {
    const user = auth.currentUser;
    const token = sessionStorage.getItem("Auth Token")
    console.log('token', token)

    return (
        token ? <Outlet /> : <Navigate to='/login' />
    )
    // return (
    //     user ? <Outlet /> : <Navigate to='/login' />
    // )
}


export default PrivateRoute