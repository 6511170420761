import React, { useState } from 'react';
// import './login.css';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../firebase';
import { toast } from 'react-toastify';
import { onValue, ref } from 'firebase/database';
import icon from '../../TSCR-01 logo.png'

const TechLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    // const getBrowserInfo = () => {
    //     const browser = Bowser.getParser(window.navigator.userAgent);
    //     return browser.getBrowser();
    // }

    const handleSubmit = (e) => {
        e.preventDefault();
        /**
         * send email and password to firebase db to authenticate user
         */
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                sessionStorage.setItem("Auth Token", userCredential._tokenResponse.refreshToken);
                let encodedEmail = user.email.replace(/\./g, ',');
                console.log("user :", sessionStorage.getItem("Auth Token"));
                // Fetch name from Firebase
                const dbRef = ref(db, `Admins/${encodedEmail}`);
                onValue(dbRef, (snapshot) => {
                    let fullname = (snapshot.val() && snapshot.val().name) || 'Anonymous';

                    sessionStorage.setItem("admin_email", user.email);
                    sessionStorage.setItem("admin_name", fullname);
                });
                console.log("token name:", sessionStorage.getItem("admin_name"));
                toast.success('login success')
                navigate("/dashboard")
            })
            .catch((error) => {
                toast.info('Email and password does not match.')
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
            });
    };

    return (
        <div className="login-container">

            <div className="login-box">
                <div class="logo-container">
                    <img src={icon} alt='' className='icon' />
                </div>
                <h2>Tech Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label>Email</label>
                        <input type="email"
                            id="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={e =>
                                setEmail(e.target.value)}
                            required
                        />

                    </div>
                    <div className="input-group">
                        <label>Password</label>
                        <input type="password"
                            placeholder="Enter your password"
                            id="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            required />
                    </div>
                    <button type="submit" >Submit</button>
                </form>
                <div className="links-bottom">
                    <p className="small">
                        <a className="text-primary" href="/">
                            Go Back
                        </a>
                    </p>

                    <p className="small">
                        <a className="text-primary" href="/reset">
                            Forgot password?
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TechLogin;
