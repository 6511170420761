import { onValue, ref, remove } from 'firebase/database';
import React, { useEffect, useState } from 'react'
import { db } from '../../firebase';
import { toast } from 'react-toastify';
import Sidebar from '../../components/sidebars/Sidebar';
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@mui/icons-material';
import { FaEye, FaTrash } from 'react-icons/fa';

const ApplicationList = () => {
    const [data, setData] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [isSmallDevice, setIsSmallDevice] = useState(false);
    const [isSmallerDevice, setIsSmallerDevice] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallDevice(window.innerWidth < 825); // Adjust the breakpoint as per your requirements
            setIsSmallerDevice(window.innerWidth < 560);
        };
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        // Call the handler initially to check the initial width
        handleResize();
        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Fetch data from Firebase Realtime Database
        const dbRef = ref(db, `Applications/`);
        const fetchData = async () => {
            try {
                onValue(dbRef, (snapshot) => {
                    if (snapshot.val() !== null) {
                        setData({ ...snapshot.val() });
                        console.log('snap one');
                    }
                }

                );

            } catch (error) {
                console.error('Error fetching data:', error);
            }
            // Cleanup listener
            return () => {
                dbRef.off();
            };
        }
        // console.log("data",filteredData)
        fetchData();
    }, []);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredData(data);
        }
        else {
            // Filter the object based on a condition
            const filtered = Object.fromEntries(
                Object.entries(data).filter(([key, value]) =>
                    key.includes(searchQuery.toLowerCase())
                    ||
                    (value.email.toLowerCase()).includes(searchQuery.toLowerCase())
                    ||
                    (value.company_name.toLowerCase()).includes(searchQuery.toLowerCase())
                )
            );
            setFilteredData(filtered);
            console.log('last:', filtered);
        }

    }, [searchQuery, data]);

    const onDelete = (email) => {
        alert(email)
        const dbRef = ref(db, `Applications/${email}`);
        if (window.confirm("Are you sure that you wanted to delete this record.")) {
            remove(dbRef)
                .then(() => {
                    toast.success("Record Deleted successfully.");
                    console.log('Item deleted successfully');
                })
                .catch((error) => {
                    toast.error('Error deleting item:', error);
                    console.error('Error deleting item:', error);
                });
        }
    }
    const itemsPerPage = 5; // or whatever you choose

    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(Object.keys(filteredData).length / itemsPerPage);

    const currentData = () => {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        const slicedKeys = Object.keys(filteredData).slice(begin, end);
        return slicedKeys.reduce((acc, key) => {
            acc[key] = filteredData[key];
            console.log('acc ', acc)
            return acc;
        }, {})
    };

    return (
        <div className="list">
            <Sidebar />
            <div className="listContainer">
                <div className="datatable">

                    <div className="search">
                        <input type="text"
                            placeholder='Search Applications...'
                            onChange={(e) =>
                                setSearchQuery(e.target.value)} />
                        <SearchOutlined />
                    </div>
                    <table className='styled-table'>
                        <thead>
                            <tr>
                                <th style={{ textAlign: "center" }}>Email</th>
                                {isSmallDevice ? null : <th style={{ textAlign: "center" }}>Company Name</th>}
                                {isSmallerDevice ? null : <th style={{ textAlign: "center" }}>ABN</th>}
                                {isSmallerDevice ? null : <th style={{ textAlign: "center" }}>Trade License Number</th>}
                                <th style={{ textAlign: "center" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                Object.keys(currentData())
                                    .map((id, index) => {
                                        return (
                                            <tr key={id}>
                                                {data[id] ? (
                                                    <>
                                                        <th scope='row'>
                                                            <div className="vehicleListItem">
                                                                {data[id].email ? data[id].email : id}

                                                            </div>
                                                        </th>
                                                        {isSmallDevice ? null : <td>

                                                            {data[id].company_name}
                                                        </td>}
                                                        {isSmallerDevice ? null : <td>{data[id].abn}</td>}
                                                        {isSmallerDevice ? null : <td>{data[id].trade_licence_num}</td>}
                                                        <td style={{ display: 'flex' }}>
                                                            <Link to={`/applications/view/${id}`}>
                                                                <FaEye className="view-icon" tooltip='view' />
                                                            </Link>

                                                            <FaTrash className="delete-icon" tooltip='delete'
                                                                onClick={() => onDelete(id)}
                                                            />

                                                        </td>

                                                    </>) : null}
                                            </tr>
                                        )
                                    })}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <button
                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button
                            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ApplicationList