import './calibrate.css'
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ref, update } from 'firebase/database';
import { auth, db } from '../../firebase';
import { toast } from 'react-toastify';

const ModalCalibration = ({ isOpen, onClose, rego }) => {

    const [selectedOption, setSelectedOption] = useState('steer');
    const [formData, setFormData] = useState({
        custom_calibration: ''
    });
    const [dueDate, setDueDate] = useState('')
    const handleDueDateChange = (event) => {
        setDueDate((event.target.value));
    };
    const handleChange = (event) => {
        setFormData({
            ...formData, [event.target.name]: event.target.value
        });
    };
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        // console.log("Axle type: ", selectedOption);

    };
    const resetForm = () => {
    // alert('hrer rerese')
        setFormData({
            ...formData, 'custom_calibration': ''
        });
        setDueDate('');
        // setSelectedOption('')

    };
    console.log("token name:", sessionStorage.getItem("admin_name"));
    const adminName = sessionStorage.getItem("admin_name");
    //set empty weight custom calibration
    const handleSubmitBtn = (type) => {
        if (!rego) {
            alert("Rego has not been entered!!!")
            return
        }
        const axle_type = selectedOption.toUpperCase()
        console.log(formData, auth.currentUser.email, dueDate)
        update(ref(db, `Vehicles/${rego}/calibrations/${Date.now()}`), {
            calibration_date_time: new Date().toLocaleString(),
            axle_type: axle_type,
            calibrating_technician: adminName ? adminName : auth.currentUser.email,
            calibration_due_date: dueDate,
            calibration_attribute: type,
            calibration_value: formData.custom_calibration,
        })
            .then(() => {
                toast.success("Entry added successfully.");
                console.log("Entry added.");
                resetForm();
            })
            .catch((error) => {
                toast.error('Error added item:', error);
                console.error('Error added item:', error);
            });
    }

    if (!isOpen) return null;

    return (
        <div className="modalOverlay">
            <div className="modalContent">
                <h2 className='mb-4'>Custom Calibration</h2>
                <div className='radio-container'>
                    <label>
                        <input
                            type="radio"
                            value="steer"
                            checked={selectedOption === 'steer'}
                            onChange={handleOptionChange}
                        />
                        Steer
                    </label>

                    <label>
                        <input
                            type="radio"
                            value="drive"
                            checked={selectedOption === 'drive'}
                            onChange={handleOptionChange}
                        />
                        Drive
                    </label>

                    <label>
                        <input
                            type="radio"
                            value="trailerA"
                            checked={selectedOption === 'trailerA'}
                            onChange={handleOptionChange}
                        />
                        Trailer_A
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="trailerB"
                            checked={selectedOption === 'trailerB'}
                            onChange={handleOptionChange}
                        />
                        Trailer_B
                    </label>
                </div>
                <div className="formInputm" >
                    <label>Next Calibration Due</label>
                    <input type='date'
                        placeholder='Calibration due'
                        name='next_calibration_due'
                        value={dueDate}
                        onChange={handleDueDateChange}
                    />
                </div>
                <Form.Control className='mb-4 mt-4'
                    type="number"
                    id="custom_calibration"
                    name="custom_calibration"
                    value={formData.custom_calibration}
                    onChange={handleChange}
                    placeholder="Enter weight or Volt..." />
                <div className="modal-main">
                    <Button className='mb-1'
                        onClick={() => handleSubmitBtn('Empty Volt')}
                        variant="success">
                        Empty Volt</Button>{' '}
                    <Button className='mb-1'
                        onClick={() => handleSubmitBtn('Loaded Volt')}
                        variant="success"> Loaded Volt</Button>{' '}
                    <Button className='mb-1'
                        onClick={() => handleSubmitBtn('Empty Weight')}
                        variant="success"> Empty Weight</Button>{' '}
                    <Button className='mb-1'
                        onClick={() => handleSubmitBtn('Loaded Weight')}
                        variant="success"> Loaded Weight</Button>{' '}
                </div>
                <hr />

                <Button className='mb-1' variant='secondary' onClick={onClose}>Close Modal</Button>
            </div>
        </div>
    )
}

export default ModalCalibration