// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'
import { getStorage } from 'firebase/storage'

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAnfA3fMtDUbvM5YETDBYiZjV3zB2fUgWk",
    authDomain: "tscr-3c766.firebaseapp.com",
    databaseURL: "https://tscr-3c766-default-rtdb.firebaseio.com",
    projectId: "tscr-3c766",
    storageBucket: "tscr-3c766.appspot.com",
    messagingSenderId: "350186936360",
    appId: "1:350186936360:web:2894e0bb4a786bfc817d1d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);
export const storage = getStorage(app);

export default app;
