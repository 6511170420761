import { onValue, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import Sidebar from '../../components/sidebars/Sidebar';
import ModalPassword from './ModalPassword';

const Application = () => {
    const [info, setInfo] = useState({});
    const { id } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const goBackHandler = () => {
        window.history.back();
    };

    useEffect(() => {
        const dbRef = ref(db, `Applications/${id}`);
        onValue(dbRef, (snapshot) => {
            if (snapshot.exists()) {
                setInfo({ ...snapshot.val() });
            } else {
                setInfo({})
            }
        });
    }, [id]);

    return (
        <div className="single">
            <Sidebar />
            <div className="singleContainer">
                <div className="top">

                    <div className="item">

                        <div className="details">
                            <h1 className="itemTitle">{info.company_name}</h1>
                            <div className="detailItem">
                                <span className="itemKey">Email:</span>
                                <span className="itemValue">{info.email}</span>
                            </div>
                            <div className="detailItem">
                                <span className="itemKey">ABN:</span>
                                <span className="itemValue">{info.abn}</span>
                            </div>
                            <div className="detailItem">
                                <span className="itemKey">Trade Licence Number:</span>
                                <span className="itemValue">
                                    {info.trade_licence_number}
                                </span>
                            </div>
                            <div className="detailItem">
                                <span className="itemKey">Address:</span>
                                <span className="itemValue">{info.address}</span>
                            </div>
                            <div className="detailItem">
                                <span className="itemKey">Representative Name:</span>
                                <span className="itemValue">
                                    {info.rep_name}
                                </span>
                            </div>
                            <div className="detailItem">
                                <span className="itemKey">Representative Contact :</span>
                                <span className="itemValue">{info.rep_contact}</span>
                            </div>
                            <div className="detailItem">
                                <span className="itemKey">Requested at :</span>
                                <span className="itemValue">{info.requested_at}</span>
                            </div>
                            <button className='btn-back' onClick={goBackHandler}>Go Back</button>
                            <button className='btn-calibrate' onClick={() => setIsModalOpen(true)}>Approve</button>
                            <ModalPassword isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} info={info} />
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default Application