import React, { useState } from 'react';
import './register.css';
import icon from '../../TSCR-01 logo.png'
import { Link, useNavigate } from 'react-router-dom';
import { ref, update } from 'firebase/database';
import { db } from '../../firebase';
import { toast } from 'react-toastify';

const Register = () => {
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const getRegistrationData = () => ({
        company_name: formData.company_name || "",
        abn: formData.abn || "",
        trade_licence_num: formData.trade_licence_num || "",
        address: formData.address ? formData.address : "",
        email: formData.email,
        rep_name: formData.rep_name || '',
        rep_contact: formData.rep_contact || "",
        requested_at: new Date().toLocaleString(),

    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        // TODO: Add logic to handle registration 
        console.log("handle submit");
        let encodedEmail = formData.email.replace(/\./g, ',');
        try {
            const registrationData = getRegistrationData(); // Get the registration data

            await update(ref(db,
                `Applications/${encodedEmail}`), registrationData);
            toast.success("Tech registration requested successfully.");
            setTimeout(() => navigate('/'), 1000);
        } catch (error) {
            toast.error('Error performing tech-register:' + error.code);
            console.error('Error adding tech-register:', error);
        }
    };

    return (
        <div className="register-container">
            <div className="logo-container">
                <img src={icon} alt='' className='icon' />
            </div>
            <h3 className='mb-5 d-flex justify-content-center '>Technician Registration Page</h3>
            <form onSubmit={handleSubmit}>
                <div className="form-group reg-group" >
                    <label>Company Name:</label>
                    <input
                        type="text"
                        name="company_name"
                        placeholder='Company Name'
                        value={formData.company_name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group reg-group">
                    <label>ABN:</label>
                    <input
                        type="text"
                        name="abn"
                        placeholder='ABN'
                        value={formData.abn}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group reg-group">
                    <label>Trade Licence Number:</label>
                    <input
                        type="text"
                        placeholder='Trade Licence Number'
                        name="trade_licence_num"
                        value={formData.trade_licence_num}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group reg-group">
                    <label>Full Address:</label>
                    <input
                        type="address"
                        placeholder='Full Address'
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group reg-group">
                    <label>Email:</label>
                    <input
                        type="email"
                        placeholder='Email'
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group reg-group">
                    <label>Representative Name:</label>
                    <input
                        type="text"
                        placeholder='Representative Name'
                        name="rep_name"
                        value={formData.rep_name}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group reg-group">
                    <label>Representative Contact:</label>
                    <input
                        type="tel"
                        placeholder='Representative Contact'
                        name="rep_contact"
                        value={formData.rep_contact}
                        onChange={handleChange}

                    />
                </div>
                {/* <div className="form-check mt-4">
                    <input className="form-check-input" type="checkbox" value="" id="termsCheckbox" required />
                    <label className="form-check-label" htmlFor="termsCheckbox">
                        I agree to the <a href="/terms-and-conditions">Terms and Conditions</a>
                    </label>
                </div> */}

                <button className='mt-4 mb-3 btnRegister' type="submit">Register</button>
            </form>
            <Link to="/" className='backButton mb-4' >
                Back to Homepage
            </Link>

        </div>
    );
};

export default Register;
