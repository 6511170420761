import { useState } from 'react';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebars/Sidebar';
import { useNavigate } from 'react-router-dom';
import { ref, set, update } from 'firebase/database';
import { auth, db, storage } from '../../firebase';
import { toast } from 'react-toastify';
import './new.css';
import { DriveFolderUploadOutlined } from '@mui/icons-material';
import { FaEye } from 'react-icons/fa';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from 'firebase/storage';
const NewAdmin = () => {
    const [image, setImage] = useState(null);
    const [url, setUrl] = useState("");
    const [state, setState] = useState({});
    const [progress, setProgress] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    /**
     * Set chosen image to a variable
     * @param {*} e 
     */
    const handleImgChoose = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }

    }

    /**
     * Update the value of input field on change
     * @param {*} e 
     */
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
        console.log(state)
    };

    const getAdminData = () => ({
        email: state.email,
        name: state.name || '',
        phone: state.phone ? state.phone : "",
        address: state.address ? state.address : "",
        dob: state.dob ? state.dob : "",
        role: state.role ? state.role : "",
        notes: state.notes ? state.notes : "",
        added_at: new Date().toLocaleString(),
        added_by: sessionStorage.getItem("admin_name"),
        imageUrl: "", // We'll set this later if an image is uploaded

    })

    const addAdminInDb = async (adminData) => {
        let encodedEmail = state.email.replace(/\./g, ',');

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, state.email, state.password);
            const user = userCredential.user;
            console.log("encodedEmail values ", encodedEmail, user.uid)

            // Storing user details in RTDB
            adminData.uid = user.uid;
            console.log(adminData)
            await update(ref(db, `Admins/${encodedEmail}`), adminData);
            toast.success("Admin Added successfully.");
            console.log("Admin Added.");
            setTimeout(() => navigate('/admins'), 1000);

        } catch (error) {
            toast.error('Error adding Admin:' + error.code);
            console.error('Error adding Admin:', error);
        }
    }

    // const email = auth.currentUser.email;
    /**
     * this method adds new product to the inventory
     * @param {*} e 
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        let encodedEmail = state.email.replace(/\./g, ',');

        const adminData = getAdminData();

        if (image) {
            console.log('state image', image.name);
            const imageRef = storageRef(storage, `admins/${encodedEmail}/` + image.name);
            //upload image
            await uploadBytesResumable(imageRef, image);

            //get download url
            const imageUrl = await getDownloadURL(imageRef);
            adminData.imageUrl = imageUrl;
            await addAdminInDb(adminData);
        } else {
            await addAdminInDb(adminData);
        }

    };

    return (
        <div className='an-new'>
            <Sidebar />
            <div className="an-newContainer">
                <div className="an-top">
                    <h1>Add new Admin</h1>
                </div>
                <div className="an-bottom">
                    <div className="an-left">
                        <img className='mt-4 an-na-img' src={
                            image ? URL.createObjectURL(image)
                                : 'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'
                        } alt=''
                        />
                        <div className="formInput mt-4 an-img-input">
                            <label htmlFor="file">
                                Image:<DriveFolderUploadOutlined className='icon' />
                            </label>
                            <input
                                type='file'
                                id='file'
                                onChange={handleImgChoose}
                                style={{ display: 'none' }}
                            />
                        </div>
                    </div>
                    <div className="an-right">
                        <form onSubmit={handleSubmit}>
                            <div className="formInput" >
                                <label>Email</label>
                                <input type='text'
                                    placeholder='Email'
                                    required
                                    name='email'
                                    value={state.email}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="formInput an-passwordInput" >
                                <label>Password</label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    required
                                    name='password'
                                    value={state.password}
                                    placeholder='Password'
                                    onChange={handleInputChange} />

                                <div className="an-toggleBtn">
                                    <FaEye className="view-icon" tooltip='view' type="button"
                                        onClick={() => setShowPassword(!showPassword)} />
                                </div>
                            </div>
                            <div className="formInput" >
                                <label>Name</label>
                                <input type='text'
                                    placeholder='Name'
                                    name='name'
                                    value={state.name}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="formInput" >
                                <label>Phone</label>
                                <input type='text'
                                    placeholder='Phone Number '
                                    name='phone'
                                    value={state.phone}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="formInput" >
                                <label>Address</label>
                                <input type='text'
                                    placeholder='Address'
                                    name='address'
                                    value={state.address}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="formInput" >
                                <label>Role</label>
                                <input type='text'
                                    placeholder='Role'
                                    name='role'
                                    value={state.role}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="formInput" >
                                <label>Date of Birth</label>
                                <input type='date'
                                    placeholder='DOB'
                                    name='dob'
                                    value={state.dob}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="formInput" >
                                <label>Notes</label>
                                <input type='text'
                                    placeholder='Notes'
                                    name='notes'
                                    value={state.notes}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <button className='an-btn-save'>Add Admin</button>

                        </form>
                    </div>
                </div>
            </div>
        </div>)
}

export default NewAdmin
