import React, { useState } from 'react';
import './gallery.css';

const GalleryImage = ({ url, openModal }) => (
    <img
        src={url}
        alt="Gallery Item"
        onClick={() => openModal(url)}
        style={{ margin: '5px', cursor: 'pointer', maxWidth: '200px', height: 'auto' }}  // Basic styling
    />
);

const ImageModal = ({ image, closeModal, nextImage, prevImage }) => (
    <div className="modal" onClick={closeModal} style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
        <img src={image} alt="Modal Content" style={{ margin: 'auto', display: 'block', maxHeight: '80vh' }} />
        <button onClick={prevImage} style={{ position: 'absolute', left: '10px', top: '50%' }}>Prev</button>
        <button onClick={nextImage} style={{ position: 'absolute', right: '10px', top: '50%' }}>Next</button>
    </div>
);

const Gallery = ({ info,isVisible  }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    const openModal = (url) => {
        setModalOpen(true);
        setSelectedImageIndex(info.imageUrls.indexOf(url));
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedImageIndex(null);
    };

    const nextImage = (event) => {
        event.stopPropagation();  // Prevent closeModal from being triggered
        if (selectedImageIndex < info.imageUrls.length - 1) {
            setSelectedImageIndex(selectedImageIndex + 1);
        }
    };

    const prevImage = (event) => {
        event.stopPropagation();  // Prevent closeModal from being triggered
        if (selectedImageIndex > 0) {
            setSelectedImageIndex(selectedImageIndex - 1);
        }
    };
    if (!isVisible) return null;

    return (
        <div>
            <div className="gallery">
                {info.imageUrls.map((url, index) => (
                    <GalleryImage key={index} url={url} openModal={openModal} />
                ))}
            </div>

            {modalOpen && 
                <ImageModal 
                    image={info.imageUrls[selectedImageIndex]} 
                    closeModal={closeModal} 
                    nextImage={nextImage} 
                    prevImage={prevImage}
                />
            }
        </div>
    );
};

export default Gallery;
