import { ref, remove, update } from 'firebase/database';
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { auth, db } from '../../firebase';
import { toast } from 'react-toastify';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const ModalPassword = ({ isOpen, onClose, info }) => {
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmitBtn = async () => {
        if (!password) {
            alert('Password is mandatory field!')
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, info.email, password);
            const user = userCredential.user;
            //storing technician details RTDB
            info.uid = user.uid;
            console.log(info)
            let encodedEmail = info.email.replace(/\./g, ',');
            await update(ref(db, `Technicians/${encodedEmail}`), info);
            const dbRef = ref(db, `Applications/${encodedEmail}`);
            remove(dbRef)
                .then(() => {
                    console.log("encodedEmail values ", info.email, user.uid)
                    toast.success("Technician Added successfully.");
                    console.log("Technician Added.");
                    setTimeout(() => navigate('/applications'), 1000);
                })
                .catch((error) => {
                    console.log(`Error: ${error.message}`);
                });

        } catch (error) {
            toast.error('Error adding technician:' + error.code);
            console.error('Error adding technician:', error);
        };
    }

    if (!isOpen) return null;

    return (
        <div className="modalOverlay">
            <div className="modalContent">
                <h2 className='mb-4'>Password Setup</h2>

                <h6 className=' row justify-content-center'>Email: {info.email}</h6>
                <Form.Control className='mb-4 mt-4'
                    type="text"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter password..." />

                <div className="modal-main">
                    <Button className='mb-1'
                        onClick={() => handleSubmitBtn()}
                        variant="success">
                        Register Technician</Button>{' '}
                </div>
                <hr />

                <Button className='row justify-content-center' variant='secondary' onClick={onClose}>Close Modal</Button>
            </div>
        </div>)
}

export default ModalPassword;