import React, { useEffect, useState } from 'react'
import {  db } from '../../firebase'
import Sidebar from '../../components/sidebars/Sidebar'
import { Navbar } from 'react-bootstrap'
import { onValue, ref } from 'firebase/database'
import { Link } from 'react-router-dom'

const Profile = () => {
    const [info, setInfo] = useState({});
    
    const goBackHandler = () => {
        window.history.back();
    };

    const email = sessionStorage.getItem('admin_email');
    // const email = auth.currentUser.email;
    let encodedEmail = email.replace(/\./g, ',');

    useEffect(() => {
        console.log('email of current user: ', encodedEmail);
        const dbRef = ref(db, `Admins/${encodedEmail}`);
        onValue(dbRef, (snapshot) => {
            if (snapshot.exists()) {
                setInfo({ ...snapshot.val() });
            } else {
                setInfo({})
            }
        });

    }, [encodedEmail]);

    console.log('profile data info', info);
    return (
        <div className="single">
            {/* <div>{auth.currentUser.email}</div> */}

            <Sidebar />
            <div className="singleContainer">
                <Navbar />
                <div className="top">
                    <div className="left">
                        <Link to={`/profile/update`}>
                            <div className="editButton">Edit</div>
                        </Link>

                        <h1 className="title">Information</h1>
                        <div className="item">
                            <img
                                src={info.imageUrl ? info.imageUrl :
                                    'https://img.favpng.com/8/0/5/computer-icons-user-profile-avatar-png-favpng-6jJk1WU2YkTBLjFs4ZwueE8Ub.jpg'
                                }
                                alt=""
                                className="itemImg"
                            />
                            <div className="details">
                                <h1 className="itemTitle">{info.name}</h1>
                                <div className="detailItem">
                                    <span className="itemKey">Email:</span>
                                    <span className="itemValue">{info.email ? info.email : email}</span>
                                </div>
                                <div className="detailItem">
                                    <span className="itemKey">Phone:</span>
                                    <span className="itemValue">{info.phone}</span>
                                </div>
                                <div className="detailItem">
                                    <span className="itemKey">Address:</span>
                                    <span className="itemValue">
                                        {info.address}
                                    </span>
                                </div>
                                <div className="detailItem">
                                    <span className="itemKey">Role:</span>
                                    <span className="itemValue">
                                        {info.role}
                                    </span>
                                </div>
                                <div className="detailItem">
                                    <span className="itemKey">DOB:</span>
                                    <span className="itemValue">{info.dob}</span>
                                </div>
                                <div className="detailItem">
                                    <span className="itemKey">Gender :</span>
                                    <span className="itemValue">{info.gender}</span>
                                </div>
                               
                            {info.added_at ?
                                <div className="detailItem">
                                    <span className="itemKey">Added At:</span>
                                    <span className="itemValue">
                                        {info.added_at}
                                    </span>
                                </div> : null
                            }
                            {info.added_by ?
                                <div className="detailItem">
                                    <span className="itemKey">Added By:</span>
                                    <span className="itemValue">
                                        {info.added_by}
                                    </span>
                                </div>
                                : null
                            }
                                {info.updated_at ?
                                <div className="detailItem">
                                    <span className="itemKey">Last Updated At:</span>
                                    <span className="itemValue">
                                        {info.updated_at}
                                    </span>
                                </div>
                                : null
                            }
                            {info.updated_by ?
                                <div className="detailItem">
                                    <span className="itemKey">Last Updated By:</span>
                                    <span className="itemValue">
                                        {info.updated_by}
                                    </span>
                                </div>
                                : null
                            }
                                <button className='btn-back' onClick={goBackHandler}>Go Back</button>

                            </div>

                        </div>
                    </div>
                    <div className="right">
                        {/* <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" /> */}
                    </div>
                </div>
                <div className="bottom">
                    <h1 className="title">Last Activities</h1>
                    {/* <List/> */}
                </div>
            </div>

        </div>
    )
}

export default Profile