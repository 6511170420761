import React from 'react'
import Sidebar from "../../components/sidebars/Sidebar";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { onValue, ref } from "firebase/database";
import { auth, db } from "../../firebase";
import './vehicle.css'
import { FaCheck, FaEye, FaTimes } from 'react-icons/fa';
import ModalCalibration from './ModalCalibration';
import { SearchOutlined } from '@mui/icons-material';
import Gallery from './Gallery';

const Vehicle = () => {
    const [info, setInfo] = useState({});
    const { id } = useParams();
    const rego = id;

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(info);
    const [isSmallDevice, setIsSmallDevice] = useState(false);
    const [isSmallerDevice, setIsSmallerDevice] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    //adding for modal of gallery
    const [isGalleryVisible, setGalleryVisible] = useState(false);
    const toggleGallery = () => {
        setGalleryVisible(prevState => !prevState);
    }

    const goBackHandler = () => {
        window.history.back();
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSmallDevice(window.innerWidth < 825); // Adjust the breakpoint as per your requirements
            setIsSmallerDevice(window.innerWidth < 560);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Call the handler initially to check the initial width
        handleResize();

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const dbRef = ref(db, `Vehicles/${id}`);
        onValue(dbRef, (snapshot) => {
            // dbRef.get().then((snapshot)=>{
            if (snapshot.exists()) {
                setInfo({ ...snapshot.val() });
            } else {
                setInfo({})
            }
        });

    }, [id]);
    console.log('snap filter', filteredData);

    useEffect(() => {
        // console.log('useeffect 2')
        console.log('useeffect 2 sq', searchQuery)

        if (searchQuery === '') {
            setFilteredData(info);
        }
        else {
            // Filter the object based on a condition
            const filtered = Object.fromEntries(
                Object.entries(info).filter(([key, value]) =>
                    key.includes(searchQuery.toLowerCase())
                    ||
                    (value.axle_type.toLowerCase()).includes(searchQuery.toLowerCase())
                    ||
                    // value.calibration_attribute ?
                    (value.calibration_attribute.toLowerCase()).includes(searchQuery.toLowerCase())
                )
            );
            setFilteredData(filtered);
            // setData(filtered)
            console.log('last:', filtered);
        }

    }, [searchQuery, info])


    return (
        <div className="single">
            {auth.currentUser ? (<Sidebar />) : null}

            <div className="singleContainer">
                {/* <Navbar /> */}
                <div className="top">
                    {auth.currentUser ? (<>

                        <Link to={`/vehicles/update/${id}`}>
                            <div className="editButton">Edit</div>
                        </Link>
                    </>) : null}
                    <div className="item">
                        <div className="details">
                            <h1 className="itemTitle">{info.name}</h1>
                            <div className="detailItem">
                                <span className="itemKey">Rego:</span>
                                <span className="itemValue">
                                    {info.rego ? info.rego : id}
                                </span>
                            </div>
                            {info.vin ? (
                                <div className="detailItem">
                                    <span className="itemKey">VIN:</span>
                                    <span className="itemValue">{info.vin}</span>
                                </div>
                            ) : null}
                            {info.scale_serial_number ? (
                                <div className="detailItem">
                                    <span className="itemKey">Scale Serial Number:</span>
                                    <span className="itemValue">
                                        {info.scale_serial_number}
                                    </span>
                                </div>
                            ) : null}

                            {info.fitted_location ? (
                                <div className="detailItem">
                                    <span className="itemKey">Fitted location:</span>
                                    <span className="itemValue">
                                        {info.fitted_location}
                                    </span>
                                </div>
                            ) : null}

                            {info.fitted_date ? (
                                <div className="detailItem">
                                    <span className="itemKey">Fitted Date:</span>
                                    <span className="itemValue">{info.fitted_date}</span>
                                </div>
                            ) : null}
                            {info.next_calibration_due ? (
                                <div className="detailItem">
                                    <span className="itemKey">Next Calibration Due:</span>
                                    <span className="itemValue">{info.next_calibration_due}</span>
                                </div>
                            ) : null}

                            {info.make ? (
                                <div className="detailItem">
                                    <span className="itemKey">Make:</span>
                                    <span className="itemValue">{info.make}</span>
                                </div>
                            ) : null}
                            {info.model ? (
                                <div className="detailItem">
                                    <span className="itemKey">Model:</span>
                                    <span className="itemValue">{info.model}</span>
                                </div>
                            ) : null}
                            {info.warranty_expiry_date ? (
                                <div className="detailItem">
                                    <span className="itemKey">Warranty Expiry Date:</span>
                                    <span className="itemValue">{info.warranty_expiry_date}</span>
                                </div>
                            ) : null}
                            {info.company_name ? (
                                <div className="detailItem">
                                    <span className="itemKey">Company Name:</span>
                                    <span className="itemValue">
                                        {info.company_name}
                                    </span>
                                </div>
                            ) : null}

                            {info.driver_name ? (
                                <div className="detailItem">
                                    <span className="itemKey">Driver Name:</span>
                                    <span className="itemValue">
                                        {info.driver_name}
                                    </span>
                                </div>
                            ) : null}
                            {/* {info.calibration_validated ? ( */}
                            <div className='detailItem'>
                                <span className='itemKey'>Is Calibration Validated:</span>
                                <span className='itemValue'>
                                    {/* <input type="checkbox" checked={info.calibration_validated} disabled /> */}
                                    {info.calibration_validated ? (
                                        <FaCheck className='checkmark checkmark--yes' />
                                    ) : (
                                        <FaTimes className='checkmark checkmark--no' />
                                    )}
                                     {/* <span className={info.calibration_validated ? 'checkmark checkmark--yes' : 'checkmark checkmark--no'}>
                                        {info.calibration_validated ? "Yes " : "No "}
                                    </span> */}
                                </span>
                            </div>
                            {/* ) : null} */}
                            {info.certificate ? (
                                <div className='detailItem'>
                                    <span className='itemKey'>Certificate: </span>
                                    <span className='itemValue'>
                                        <a href={info.certificate} target="_blank" rel="noopener noreferrer"
                                        >View Certificate</a>
                                    </span>
                                </div>
                            ) : null}
                            {info.notes ? (
                                <div className="detailItem">
                                    <span className="itemKey">Notes:</span>
                                    <span className="itemValue">
                                        {info.notes}
                                    </span>
                                </div>
                            ) : null}
                            {info.added_at ? (
                                <div className="detailItem">
                                    <span className="itemKey">Added at:</span>
                                    <span className="itemValue">
                                        {info.added_at}
                                    </span>
                                </div>
                            ) : null}

                            {info.added_by ? (
                                <div className="detailItem">
                                    <span className="itemKey">Added By:</span>
                                    <span className="itemValue">
                                        {info.added_by}
                                    </span>
                                </div>
                            ) : null}

                            {info.updated_at ? (
                                <div className="detailItem">
                                    <span className="itemKey">Last Updated at:</span>
                                    <span className="itemValue">
                                        {info.updated_at}
                                    </span>
                                </div>
                            ) : null}
                            {info.updated_by ? (
                                <div className="detailItem">
                                    <span className="itemKey">Last Updated By:</span>
                                    <span className="itemValue">
                                        {info.updated_by}
                                    </span>
                                </div>
                            ) : null}

                            {/* <div> */}
                            <button className='btn-back' onClick={goBackHandler}>Go Back</button>
                            {auth.currentUser ? (<>
                                <button className='btn-calibrate' type="button" onClick={() => setIsModalOpen(true)}>
                                    Calibrate
                                </button>
                                <ModalCalibration isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} rego={info.rego ? info.rego : id} />
                            </>) : null}

                            {/* </div> */}
                            {/* Gallery */}
                            {info.imageUrls ? (
                                <>
                                    {/* <button onClick={() => setGalleryVisible(!isGalleryVisible)}>Show Gallery</button> */}
                                    <button className='btn-gallery' type="button" onClick={toggleGallery}>
                                        {isGalleryVisible ? 'Hide Gallery' : 'Show Gallery'}
                                    </button>
                                    <Gallery info={info} isVisible={isGalleryVisible} />
                                </>
                                // <Gallery info={info}/>
                            ) : null}
                        </div>

                    </div>
                </div>
                <div className="bottom">
                    {info.calibrations ? (
                        <>
                            <h1 className="title">Calibration History</h1>
                            {/* <div className="search">
                                <input type="text"
                                    placeholder='Search Vehicle...'
                                    onChange={(e) =>
                                        setSearchQuery(e.target.value)} />
                                <SearchOutlined />
                            </div> */}

                            <table className='styled-table'>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>Calibration Date </th>
                                        {isSmallDevice ? null : <th style={{ textAlign: "center" }}>Axle Type</th>}
                                        {isSmallDevice ? null : <th style={{ textAlign: "center" }}>Calibration Atttribute </th>}
                                        {isSmallerDevice ? null : <th style={{ textAlign: "center" }}> Value </th>}
                                        {isSmallerDevice ? null : <th style={{ textAlign: "center" }}>Calibration Due </th>}

                                        <th style={{ textAlign: "center" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        Object.keys(filteredData.calibrations ? filteredData.calibrations : info.calibrations).map((id, index) => {
                                            return (
                                                <tr key={id}>
                                                    <th scope='row'>
                                                        <div className="vehicleListItem">
                                                            {/* {info.calibrations[id].calibration_due_date} */}

                                                            {info.calibrations[id].calibration_date_time}
                                                        </div>
                                                    </th>
                                                    {isSmallDevice ? null : <td>

                                                        {info.calibrations[id].axle_type}
                                                    </td>}
                                                    {isSmallDevice ? null : <td>
                                                        {info.calibrations[id].calibration_attribute}
                                                    </td>}
                                                    {isSmallerDevice ? null : <td>
                                                        {info.calibrations[id].calibration_value}
                                                    </td>}
                                                    {isSmallerDevice ? null : <td>
                                                        {info.calibrations[id].calibration_due_date}
                                                    </td>}
                                                    <td style={{ display: 'flex' }}>
                                                        <Link to={`/calibrations/${rego}/${id}`}>

                                                            <FaEye className="view-icon" tooltip='view' />
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </>
                    ) : null
                    }
                </div>
            </div>
        </div>
    );
};




export default Vehicle