import React from "react";
import { FaSistrix } from 'react-icons/fa';
import './search.css';
import { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

const Search = (props) => {
    const location = useLocation();
    const [state, setState] = useState(
        location.state ? location.state : ""
    );
    const navigate = useNavigate();
    const goBack = () => {
        navigate("/");
    }
    const searchRego = (e) => {
        e.preventDefault();
        // alert('submitted ' + query)
        // navigate("/search", { state });
    };

    return (
        <div className='search__main'>
            <div className="search__form">
                <div className="search__form-logo">
                    <img src="/images/icon_trucktronix.png"
                        alt=""
                        onClick={goBack} />
                </div>
                <div className="search__form-input">
                    <form className='home__form'
                     onSubmit={searchRego}
                    >
                        <input
                            type="text"
                            className='home__input'
                            onChange={(e) =>
                                setState(e.target.value)}
                            value={state}
                            placeholder="Search by Rego..."
                        />
                        <FaSistrix className='search__icon' />

                        {/* <div className="home__group">
                            <input type='submit' className='home__btn' value='Admin Login' />
                        </div> */}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Search