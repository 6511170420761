import './App.css';
import Search from './components/Search';
import HomePage from './pages/HomePage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PasswordResetForm from './pages/reset/PasswordReset';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './pages/login/Login';
import Home from './pages/home/Home';
import VehicleList from './pages/vehicles/VehicleList';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewVehicle from './pages/vehicles/NewVehicle';
import Vehicle from './pages/vehicles/Vehicle';
import UpdateVehicle from './pages/vehicles/UpdateVehicle';
import ViewCalibration from './pages/vehicles/ViewCalibration';
import PrivateRoute from './PrivateRoute';
import Profile from './pages/profile/Profile';
import UpdateProfile from './pages/profile/UpdateProfile';
import AdminList from './pages/admins/AdminList';
import Admin from './pages/admins/Admin';
import TechLogin from './pages/tech-login/Login';
import Register from './pages/tech-register/Register';
import NewAdmin from './pages/admins/NewAdmin';
import UpdateAdmin from './pages/admins/UpdateAdmin';
import ApplicationList from './pages/tech-register/ApplicationList';
import Application from './pages/tech-register/Application';
import Technician from './pages/tech-register/Technician';
import TechnicianList from './pages/tech-register/TechnicianList';

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/search" exact element={<Search />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/tech-login" exact element={<TechLogin />} />
          <Route path="/tech-register" exact element={<Register />} />
          <Route path="/reset" element={<PasswordResetForm />} />
          <Route path="/vehicles/view/:id" element={<Vehicle />} />
          <Route path="/calibrations/:rego/:id" element={<ViewCalibration />} />

          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Home />} />
            <Route path='/my-profile' element={<Profile />} />
            <Route path='/profile/update' element={<UpdateProfile />} />
            <Route path='/admins' element={<AdminList />} />
            <Route path="/admins/new" element={<NewAdmin />} />
            <Route path="/admins/view/:id" element={<Admin />} />
            <Route path='/admins/update/:id' element={<UpdateAdmin />} />

            <Route path="/vehicles" element={<VehicleList />} />
            <Route path="/vehicles/new" element={<NewVehicle />} />
            <Route path="/vehicles/update/:id" element={<UpdateVehicle />} />
        
            <Route path="/applications" element={<ApplicationList />} />
            <Route path="/applications/view/:id" element={<Application />} />

            <Route path="/technicians" element={<TechnicianList />} />
            <Route path="/technicians/view/:id" element={<Technician />} />

          </Route>

        </Routes>
      </Router>
    </div>
  );
}

export default App;
