import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FireTruckOutlined, KeyboardArrowUp, People, PersonOutlineOutlined, PhoneEnabledRounded } from '@mui/icons-material';
import { get, off, onValue, ref } from 'firebase/database';
import { db } from '../../firebase';
import './widget.scss';
import { FaChalkboardTeacher } from 'react-icons/fa';

const Widget = ({ type }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const fetchCount = async () => {
            //   const dataRef = ref(db, type === 'admin' ? 'Admins' : 'Vehicles');
            const dataRef = ref(db, type === 'admin' ? 'Admins' : type === 'vehicle' ? 'Vehicles' : type === 'application' ? 'Applications' : 'Technicians');
            try {
                const snapshot = await get(dataRef);
                const data = snapshot.val();
                if (data) {
                    setCount(Object.keys(data).length);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchCount();
    }, [type]);

    const data = {
        title: type === 'admin' ? 'Admins' : type === 'vehicle' ? 'Vehicles' : type==='application'?'Applications':'Technicians',
        isMoney: false,
        amount: count,
        linkTitle: `See all ${type === 'admin' ? 'Admins' : type === 'vehicle' ? 'Vehicles' : type==='application'?'Applications':'Technicians'}`,
        link: `/${type}s`, // Assuming the route is pluralized (admins, vehicles)
        icon: (
            type === 'admin' ? (
                <PersonOutlineOutlined
                    className="icon"
                    style={{
                        color: 'crimson',
                        backgroundColor: 'rgba(255,0,0,0.2)',
                    }}
                />
            ) : type === 'vehicle' ? (
                <FireTruckOutlined
                    className="icon"
                    style={{
                        backgroundColor: 'rgba(218,165,32,0.2)',
                        color: 'goldenrod',
                    }}
                />
            ): type === 'application' ? (
                <People
                    className="icon"
                    style={{
                        backgroundColor: 'rgba(218,165,32,0.2)',
                        color: 'goldenrod',
                    }}
                />
            ) :
                (
                    <
                        FaChalkboardTeacher
                        className="icon"
                        style={{
                            backgroundColor: 'rgba(218,165,32,0.2)',
                            color: 'goldenrod',
                        }}
                    />
                )
        ),
    };

    return (
        <div className="widget">
            <div className="left">
                <span className="title">{data.title}</span>
                <span className="counter">{data.amount}</span>
                <span className="link">
                    <Link to={data.link}>{data.linkTitle}</Link>
                </span>
            </div>
            <div className="right">
                <div className="percentage positive">
                    <KeyboardArrowUp />
                    10%
                </div>
                {data.icon}
            </div>
        </div>
    );
};

export default Widget;
