import { onValue, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import Sidebar from '../../components/sidebars/Sidebar';

const Technician = () => {
    const [info, setInfo] = useState({});
    const { id } = useParams();

    const goBackHandler = () => {
        window.history.back();
    };

    useEffect(() => {
        const dbRef = ref(db, `Technicians/${id}`);
        onValue(dbRef, (snapshot) => {
            if (snapshot.exists()) {
                setInfo({ ...snapshot.val() });
            } else {
                setInfo({})
            }
        });
    }, [id]);

    return (
        <div className="single">
            <Sidebar />
            <div className="singleContainer">
                <div className="top">
                    <div className="item">
                        <div className="details">
                            {renderDetailItem('Company Name', info.company_name)}
                            {renderDetailItem('Email', info.email)}
                            {renderDetailItem('ABN', info.abn)}
                            {renderDetailItem('Trade Licence Number', info.trade_licence_number)}
                            {renderDetailItem('Address', info.address)}
                            {renderDetailItem('Representative Name', info.rep_name)}
                            {renderDetailItem('Representative Contact', info.rep_contact)}
                            {renderDetailItem('Requested at', info.requested_at)}
                            <button className='btn-back' onClick={goBackHandler}>Go Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const renderDetailItem = (key, value) => (
    <div className="detailItem">
        <span className="itemKey">{key}:</span>
        <span className="itemValue">{value}</span>
    </div>
);
export default Technician