import React, { useEffect, useState } from 'react'
import Sidebar from "../../components/sidebars/Sidebar";
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@mui/icons-material';
import { FaCheck, FaEdit, FaEye, FaTimes, FaTrash } from 'react-icons/fa';
import './vehiclelist.css';
import { onValue, ref, remove } from 'firebase/database';
import { db } from '../../firebase';
import { toast } from 'react-toastify';

const VehicleList = () => {
    const [data, setData] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [isSmallDevice, setIsSmallDevice] = useState(false);
    const [isSmallerDevice, setIsSmallerDevice] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallDevice(window.innerWidth < 825); // Adjust the breakpoint as per your requirements
            setIsSmallerDevice(window.innerWidth < 560);
        };
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        // Call the handler initially to check the initial width
        handleResize();
        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const fetchData = async () => {
        // Fetch data from Firebase Realtime Database
        const dbRef = ref(db, `Vehicles/`);
        try {
            onValue(dbRef, (snapshot) => {
                if (snapshot.val() !== null) {
                    setData({ ...snapshot.val() });
                    console.log('snap one');
                }
            }

            );

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        // Cleanup listener
        return () => {
            dbRef.off();
        };
    }
    useEffect(() => {
        // console.log("data",filteredData)
        fetchData();
    }, []);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredData(data);
        }
        else {
            // Filter the object based on a condition
            const filtered = Object.fromEntries(
                Object.entries(data).filter(([key, value]) =>
                    key.includes(searchQuery.toLowerCase())
                    ||
                    (value.rego.toLowerCase()).includes(searchQuery.toLowerCase())
                    ||
                    (value.vin.toLowerCase()).includes(searchQuery.toLowerCase())
                )
            );
            setFilteredData(filtered);
            console.log('last:', filtered);
        }

    }, [searchQuery, data]);

    const onDelete = (rego) => {
        const dbRef = ref(db, `Vehicles/${rego}`);
        if (window.confirm("Are you sure that you wanted to delete this record.")) {
            remove(dbRef)
                .then(() => {
                    toast.success("Record Deleted successfully.");
                    console.log('Item deleted successfully');
                })
                .catch((error) => {
                    toast.error('Error deleting item:', error);
                    console.error('Error deleting item:', error);
                });
            // setTimeout(() => navigate('/admin/vehicles'), 9000);

        }
    }

    const itemsPerPage = 10; // or whatever you choose

    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(Object.keys(filteredData).length / itemsPerPage);
    // console.log("pages ", currentPage, Object.keys(filteredData))

    const currentData = () => {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        // setData( Object.keys(filteredData).slice(begin, end))
        const slicedKeys = Object.keys(filteredData).slice(begin, end);
        return slicedKeys.reduce((acc, key) => {
            acc[key] = filteredData[key];
            console.log('acc ', acc)
            return acc;
        }, {})
    };

    return (
        <div className="list">
            <Sidebar />
            <div className="listContainer">
                {/* <Navbar /> */}
                <div className="datatable">
                    <div className="datatableTitle">
                        Add New Vehicle
                        <Link to="/vehicles/new" className="link">
                            Add New
                        </Link>
                    </div>
                    <div className="search">
                        <input type="text"
                            placeholder='Search Vehicle...'
                            onChange={(e) =>
                                setSearchQuery(e.target.value)} />
                        <SearchOutlined />
                    </div>
                    <table className='styled-table'>
                        <thead>
                            <tr>
                                <th style={{ textAlign: "center" }}>Rego</th>
                                {isSmallDevice ? null : <th style={{ textAlign: "center" }}>VIN</th>}
                                {isSmallerDevice ? null : <th style={{ textAlign: "center" }}>Warranty Expiry</th>}
                                {isSmallerDevice ? null : <th style={{ textAlign: "center" }}>Scale Serial Number</th>}
                                {isSmallerDevice ? null : <th style={{ textAlign: "center" }}>Valid Calibration</th>}
                                {isSmallerDevice ? null : <th style={{ textAlign: "center" }}>Certificate</th>}
                                <th style={{ textAlign: "center" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                // setData(currentData)
                                // Object.keys(filteredData ? filteredData : data).map((id, index) => {
                                Object.keys(currentData())
                                    .map((id, index) => {

                                        return (
                                            <tr key={id}>
                                                {data[id] ? (
                                                    <>
                                                        <th scope='row'>
                                                            <div className="vehicleListItem">
                                                                {data[id].rego ? data[id].rego : id}

                                                            </div>
                                                        </th>
                                                        {isSmallDevice ? null : <td>

                                                            {data[id].vin}
                                                        </td>}
                                                        {isSmallerDevice ? null : <td>{data[id].warranty_expiry_date}</td>}
                                                        {isSmallerDevice ? null : <td>{data[id].scale_serial_number}</td>}
                                                        {isSmallerDevice ? null : <td> {data[id].calibration_validated ? (
                                                            <FaCheck className='checkmark checkmark--yes' />
                                                        ) : (
                                                            <FaTimes className='checkmark checkmark--no' />
                                                        )}
                                                        </td>}
                                                        {isSmallerDevice ||
                                                            <td>
                                                                {data[id].certificate ? (
                                                                    <a href={data[id].certificate} target="_blank" rel="noopener noreferrer"
                                                                    >View</a>
                                                                ) : (
                                                                    'N/A'
                                                                )
                                                                }
                                                            </td>
                                                        }<td style={{ display: 'flex' }}>
                                                            <Link to={`/vehicles/view/${id}`}>
                                                                <FaEye className="view-icon" tooltip='view' />
                                                            </Link>
                                                            <Link to={`/vehicles/update/${id}`}>
                                                                <FaEdit className="edit-icon" tooltip="edit"
                                                                />
                                                            </Link>

                                                            {/* <FaTrash className="delete-icon" tooltip='delete'/> */}

                                                            {/* <Link
                                                    to={`/admin/vehicles`}
                                                > */}
                                                            <FaTrash className="delete-icon" tooltip='delete'
                                                                onClick={() => onDelete(id)}
                                                            />
                                                            {/* </Link> */}


                                                        </td>

                                                    </>) : null}
                                            </tr>
                                        )
                                    })}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <button
                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button
                            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>

                </div>

            </div>

        </div>

    )
}

export default VehicleList;