import React, { useState } from 'react';
import './passwordreset.css'; // Import the CSS file
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase';

const PasswordResetForm = () => {
    const [email, setEmail] = useState('');
    const [resetSent, setResetSent] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission (e.g., send reset password email)
        try {
            await sendPasswordResetEmail(auth, email);
            setResetSent(true);
            setError('');
        } catch (error) {
            console.log(error.message)
            setError("Error while reseting the password!");
            setResetSent(false);
        }
    };

    return (
        <div className="containerReset">
            <div className=" card">
                <div className="card-body">

                    <div className="password-reset-form-container mt-4">
                        <h4 className="card-title mb-4">Reset Password</h4>
                        {resetSent ? (
                            <div>
                                <p className="large mb-5 pb-lg-2">An email has been sent to your email address with instructions to reset your password.</p>
                                <p className="large mb-5 pb-lg-2">
                                    <a className="text-muted"
                                        href="/login">
                                        Go back to Login
                                    </a>
                                </p>

                            </div>
                        ) : (
                            <form onSubmit={handleSubmit} className="password-reset-form">

                                <div className="form-group">
                                    <label className="mb-4" htmlFor="email">Email address</label>
                                    <input type="email"
                                        className="form-control mb-4"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        placeholder="Enter your email" />
                                </div>
                                <button
                                    className='mb-4 btnSubmit'
                                    type="submit">Reset Password</button>
                                {error && <p className='error-text'>{error}</p>}
                                <p className="large mb-5 pb-lg-2">
                                    <a className="text-muted"
                                        href="/login">
                                        Go back to Login
                                    </a>
                                </p>

                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordResetForm;
